<template>
  <div>
    <v-dialog
      v-model="dialogShow"
      :max-width="formDialogOptions.width"
      :style="{ zIndex: formDialogOptions.zIndex }"
      @keydown.esc.prevent="closeForm"
      persistent
      fullscreen
      transition="dialog-bottom-transition"
    >
<!--      :fullscreen="$vuetify.breakpoint.smAndDown"-->

      <v-card>
        <v-form  v-model="valid" ref="form">

          <v-toolbar
              dark
              color="brown"
              class="color-gradient-1"
              dense
          >
            <v-btn
                icon
                dark
                @click="closeForm"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>{{ title }}</v-toolbar-title>

            <span class="ml-2 mr-2 font-weight-medium grey--text"> | </span>
            <span v-if="formMode === 'EDIT_FORM' ">EDIT </span>
            <span class="font-weight-light ml-1 mr-1">ITEM</span>
            <span v-if="formMode === 'NEW_FORM' "> BARU</span>

            <span class="ml-2 mr-2 font-weight-medium grey--text" v-show="isItemModified"> | </span>
            <v-chip
                class="ma-2"
                color="warning"
                outlined
                x-small
                v-show="isItemModified"
            >
              <v-icon left>
                mdi-pencil
              </v-icon>
              modfied
            </v-chip>

            <v-spacer></v-spacer>
            <v-toolbar-items >
              <v-btn
                  dark
                  text
                  @click="save"
                  :disabled="!valid || isItemModified===false"
                  class="hidden-md-and-up"
              >
                Simpan
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-text>
            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-container class="pa-4 text-center">
                  <v-row
                      class="fill-height"
                      align="center"
                      justify="center"
                  >
                    <template>
                      <v-col
                          cols="12"
                          sm="12"
                          md="12"
                      >

                        <v-flex class="d-flex flex-wrap">
                          <v-hover v-slot="{ hover }">
                          <v-card
                              :elevation="hover ? 10 : 1"
                              :class="[{ 'on-hover': hover }, hover?'card-hover-opacity':'card-not-hover-opacity']"
                          >
                            <v-img
                                :lazy-src="lookupImageUrlLazy(itemModified)"
                                :src="lookupImageUrl(itemModified)"
                            >
                              <v-card-title class="text-h6 white--text fill-height">

                                <v-row
                                    class="fill-height flex-column"
                                    justify="space-between"
                                >
                                  <v-spacer/>
                                  <div class="align-self-center">
                                    <v-btn
                                        :class="{ 'show-btns': hover }"
                                        :color="transparent"
                                        icon
                                        large
                                        dark
                                        outlined
                                        @click="showDialogUpload"
                                    >
                                      <v-icon
                                          :class="{ 'show-btns': hover }"
                                          :color="transparent"
                                          large
                                      >
                                        mdi-upload
                                      </v-icon>
                                    </v-btn>
                                  </div>
                                </v-row>

                              </v-card-title>
                            </v-img>

                          </v-card>
                        </v-hover>
                        </v-flex>

                      </v-col>
                    </template>
                  </v-row>
                </v-container>
              </v-col>
              <v-col cols="12" sm="8" md="8">
                <v-row>
                  <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      class="mt-4"
                  >
                    <v-text-field
                        v-model="itemModified.spcode"
                        :rules="rulesLenght"
                        label="Kode"
                        dense
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="4"
                      md="4"
                  >
                    <v-switch
                        v-model="itemModified.statusActive"
                        :label="itemModified.statusActive?'Aktif':'Non-Aktif'"
                        x-small
                        class="pa-3"
                        dense
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col
                      cols="12"
                      sm="12"
                      md="12"
                  >
                    <v-text-field
                        v-model="itemModified.spname"
                        label="Nama Pegawai/Salesman"
                        :rules="rulesNotEmtpy"
                        dense
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                      cols="12"
                      sm="5"
                      md="5"
                  >
                    <v-text-field
                        v-model="itemModified.phone"
                        label="Phone"
                        type="number"
                        prepend-inner-icon="mdi-phone"
                        :prefix="`+${itemModified.countryCode}`"
                        dense
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="7"
                      md="7"
                  >
                    <v-text-field
                        v-model="itemModified.email"
                        label="Email"
                        prepend-inner-icon="mdi-email"
                        dense
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="red lighten-5">
                  <v-col
                      cols="12"
                      sm="4"
                      md="4"
                  >

                    <v-menu
                        v-model="menuDate1"
                        :close-on-content-click="false"
                        max-width="290"
                    >
                      <template v-slot:activator="{ on, attrs } ">
                        <v-text-field
                            :value="computedDateFormattedDatefns(itemModified.bornDate)"
                            prepend-inner-icon="mdi-calendar"
                            clearable
                            label="Tanggal Lahir"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            @click:clear="itemModified.bornDate = null"
                            dense
                            hide-details
                        ></v-text-field>
                      </template>

                      <v-date-picker
                          v-model="itemModified.bornDate"
                          @change="menuDate1 = false"
                      ></v-date-picker>
                    </v-menu>

                  </v-col>
                  <v-col
                      cols="12"
                      sm="7"
                      md="7"
                  >
                    <v-text-field
                        v-if="false"
                        label="Nomor Rek ex. BCA-12345679"
                        dense
                        hide-details
                    ></v-text-field>
<!--                    v-model="itemModified.nomorRekening"-->
                  </v-col>

                </v-row>

              </v-col>
            </v-row>


          </v-card-text>

          <v-card-text>
            <v-container>

              <v-row>
                <v-col
                    cols="12"
                    sm="4"
                    md="4"
                >
                  <v-autocomplete
                      v-model="itemModified.fdivisionBean"
                      :items="itemsFDivision"
                      :rules="rulesNotEmtpy"
                      item-value="id"
                      item-text="description"
                      auto-select-first
                      dense
                      chips
                      deletable-chips
                      color="blue-grey lighten-2"
                      label="Division/Branch"
                      :hint="`Division = Branch/Distributor : ${lookupFDivision(itemModified.fdivisionBean)}`"
                      persistent-hint
                      single-line
                      small-chips
                  ></v-autocomplete>
                </v-col>
                <v-col
                    cols="12"
                    sm="3"
                    md="3"
                >
                  <v-autocomplete
                      v-model="itemModified.salesType"
                      :items="itemsESalesType"
                      :rules="rulesNotEmtpy"
                      item-value="id"
                      item-text="description"
                      auto-select-first
                      dense
                      chips
                      deletable-chips
                      color="blue-grey lighten-2"
                      label="Jabatan"
                      hint="Jabatan/Sales Type"
                      persistent-hint
                      single-line
                      small-chips
                  ></v-autocomplete>

                </v-col>
                <v-col
                    cols="12"
                    sm="5"
                    md=""
                >

                  <v-autocomplete
                      v-model="itemModified.childOf"
                      :items="itemsFSalesmanExceptMe"
                      item-value="id"
                      item-text="spname"
                      auto-select-first
                      dense
                      chips
                      deletable-chips
                      color="blue-grey lighten-2"
                      label="Team Dari Salesman"
                      :hint="`${itemModified.spname}  adalah Team Dari: ${lookupFSalesman(itemModified.childOf) }`"
                      persistent-hint
                      small-chips
                  >
                    <template v-slot:selection="data">
                      <v-chip
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          close
                          @click="data.select"
                          @click:close="itemModified.childOf=undefined"
                      >
                        <v-avatar left>
                          <v-img :lazy-src="lookupImageUrlLazy(data.item)" :src="lookupImageUrl(data.item)"></v-img>
                        </v-avatar>
                        {{ data.item.spname }}
                        <span class="font-weight-light caption"> *{{ data.item.spcode }} </span>
                      </v-chip>
                    </template>

                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-avatar>
                          <v-img :lazy-src="lookupImageUrlLazy(data.item)" :src="lookupImageUrl(data.item)"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <!--?                          <v-list-item-title v-html="data.item.spname"></v-list-item-title>-->
                          <v-list-item-title>{{data.item.spname}} *{{data.item.spcode}}</v-list-item-title>
                          <v-list-item-subtitle >
                            {{lookupFDivision(data.item.fdivisionBean)}}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>

                  </v-autocomplete>

                </v-col>
              </v-row>

            </v-container>
          </v-card-text>


          <v-card-actions>
            <v-chip
                class="ml-4"
                color="error"
                outlined
                close
                small
                v-show="formDialogOptions.errorMessage"
            >
              {{formDialogOptions.errorMessage}}
            </v-chip>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                outlined
                text
                @click="closeForm"
                class="hidden-sm-and-down"
            >
              Batal
            </v-btn>
            <v-btn
                color="blue darken-1"
                outlined
                text
                @click="save"
                :disabled="!valid || isItemModified===false"
                class="hidden-sm-and-down"
            >
              Simpan
            </v-btn>
          </v-card-actions>
        </v-form>

      </v-card>


      <CloseConfirmDialog
        ref="refCloseConfirmDialog"
        @eventFromCloseConfirm="passingEventFromCloseConfirm"
      ></CloseConfirmDialog>

      <UploadImageDialog
          ref="refUploadDialog"
          @eventUploadSuccess="completeUploadSuccess"
      >
      </UploadImageDialog>
      <v-snackbar
          v-model="snackbar"
      >
        {{ snackBarMesage }}
        <template v-slot:action="{ attrs }">
          <v-btn
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>

    </v-dialog>
  </div>

</template>

<script>
import FPegawaiService from '../../../services/apiservices/f-pegawai-service';

import CloseConfirmDialog from "../../utils/CloseConfirmDialog";
import FormMode from "../../../models/form-mode";
import FSalesman from "../../../models/f-salesman";
import FileService from "../../../services/apiservices/file-service";
import UploadImageDialog from "../../utils/UploadImageDialog";
import {  ESalesTypes } from "../../../models/e-sales-type";
import {format, parseISO} from "date-fns";

export default {
  name: "UsersDialog",
  components: { CloseConfirmDialog, UploadImageDialog },
  props:{
    formMode: String,
    itemsFSalesman:[]
  },
  data() {
    return {
      title: 'SALESMAN',
      snackBarMesage: '',
      snackbar: false,

      date: format(parseISO(new Date().toISOString()), 'dd-MMM-yyyy'),
      menuDate1: false,

      imageOverlay: false,

      dialogShow: false,
      formDialogOptions: {
        title1: '',
        title2: '',
        message1: '',
        message2: '',
        errorMessage: '',
        color: "grey lighten-3",
        width:800,
        zIndex: 200,
        noconfirm: false,
      },

      selectedItemIndex: -1,
      itemDefault: '',
      itemModified: new FSalesman(0, '', ''),
      selectFDivision: {id: 0, kode1: '', description: '' },
      itemsFDivision: [],
      itemsESalesType: ESalesTypes,
      // itemsFSalesman:[],

      valid: false,
      rulesLenght: [
        v => !!v || ' Tidak Boleh Kosong',
        v => v.length <= 255 || 'Maksimal 255 Karakter',
        v => v.length > 1 || 'Harus lebih besar dari 1 karakter'
      ],
      rulesNotEmtpy: [
        v => !!v || 'Tidak boleh kosong'
      ],

      transparent: 'rgba(255, 255, 255, 0)',

    }
  },
  computed: {
    isItemModified() {
      const defaultItem = JSON.stringify(this.itemDefault)
      const modifiedItem = JSON.stringify(this.itemModified)
      return defaultItem !== modifiedItem
    },
    itemsFSalesmanExceptMe(){
      return this.itemsFSalesman.filter( x => x.id !== this.itemModified.id)
    }
  },
  watch: {
  },

  methods: {

    showDialogUpload(){
      if (this.itemModified.spcode !==undefined &&
          this.itemModified.spname !==undefined &&
          this.itemModified.fdivisionBean !==undefined){

        if (this.itemModified.id===0){
          // this.$emit('eventSaveItemWithoutClose', false)
          this.saveCreateOnly()
        }
        this.$refs.refUploadDialog.showDialog()

      }else{
        this.snackBarMesage = 'Kode, Deskripsi dan Divisi harus diisi dahulu'
        this.snackbar = true
      }

    },

    completeUploadSuccess: function (val){

      // console.log(val)
      //Hapus Dulu yang lama
      console.log(this.itemModified.avatarImage)

      if (this.itemModified.avatarImage !==undefined && this.itemModified.avatarImage !== '' ) {
        FileService.deleteImage(this.itemModified.avatarImage).then(
            response=>{
              console.log(response.data)
            },
            error => {
              console.log(error.response)
            }
        )
      }

      if (val.fileName !==""){

        this.$refs.refUploadDialog.closeDialog()
        this.itemModified.avatarImage = val.fileName
        this.saveUpdateOnly()
      }

    },

    showDialog(selectedIndex, item, itemsFDivision) {
      // console.log("show UserDialog " + item.id)
      // console.log(this.formMode)
      this.dialogShow = !this.dialogShow
      if (selectedIndex >-1) {
        this.selectedIndex = selectedIndex
        this.initializeEditMode(item)
      }else {
        this.itemDefault =  new FSalesman(0, '', ''),
        this.itemModified =  new FSalesman(0, '', ''),
        this.selectedIndex = -1
      }

      this.itemsFDivision = itemsFDivision

    },
    setDialogState(value){
      this.dialogShow =value
    },
    save(){
      if (this.isItemModified===false){
        //Close aja
        this.dialogShow = false
        this.$emit('eventFromFormDialog1', this.itemModified)
        return
      }
      if (this.itemModified.phone !=undefined){
        this.itemModified.phone = parseInt(this.itemModified.phone);
      }

      if (this.$refs.form.validate()){

        if (this.formMode===FormMode.EDIT_FORM) {
          FPegawaiService.updateFPegawai(this.itemModified).then(
              () => {

                // console.log(JSON.stringify(response.data))

                this.$emit('eventFromFormDialogEdit', this.itemModified)
              },
              error => {
                // console.log(error);
                this.formDialogOptions.errorMessage = error.response.data.message
              }
          )
        }else {
          FPegawaiService.createFPegawai(this.itemModified).then(
              response =>{
                this.$emit('eventFromFormDialogNew', response.data)
              },
              error => {
                this.formDialogOptions.errorMessage = error.response.data.message
              }
          )
        }
      }
    },
    saveCreateOnly(){
      FPegawaiService.createFPegawai(this.itemModified).then(
          response =>{
            /**
             * dipaksa Save dan Update Dahulu
             */
            // this.initializeEditMode(response.data)
            this.$emit('update:formMode', FormMode.EDIT_FORM)
            this.itemModified.id = response.data.id
          },
          error => {
            this.formDialogOptions.errorMessage = error.response.data.message
          }
      )
    },
    saveUpdateOnly(){
      FPegawaiService.updateFPegawai(this.itemModified).then(
          () => {
          },
          error => {
            // console.log(error);
            this.formDialogOptions.errorMessage = error.response.data.message
          }
      )
    },
    closeForm(){
      if (! this.isItemModified){
        this.dialogShow = false
        this.$emit('eventFromFormDialog1', this.itemModified)
      }else {
        this.$refs.refCloseConfirmDialog.showDialog(" Sudah terdapat modifikasi data", "Tetap tutup dan reset perubahan?")
      }
    },
    passingEventFromCloseConfirm(value){
      if (value==='OKE') this.dialogShow = false
    },
    initializeEditMode(item){
      // this.itemDefault = item
      // this.itemModified = item

      this.formDialogOptions.errorMessage = ''

      FPegawaiService.getFPegawaiById(item.id).then(
          response =>{
            // console.log(response.data)
            this.itemDefault = Object.assign({}, response.data)
            this.itemModified = response.data
          },
          error =>{
            console.log(error)
          }
      )
    },
    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id===fdivisionBean)
      if (str.length>0){
        return `${str[0].description} (${str[0].kode1})`
      }else {
        return '-'
      }
    },

    lookupFSalesman (fsalesmanBean) {
      const str = this.itemsFSalesman.filter(x => x.id===fsalesmanBean)
      if (str.length>0){
        return `${str[0].spname} *${str[0].spcode}`
      }else {
        return '-'
      }
    },

    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/account_icon.png'
      }else {
        return FileService.image_url_medium(item.avatarImage)
      }
    },
    lookupImageUrlLazy(){
      return './assets/images/account_icon.png'
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    computedDateFormattedDatefns (value) {
      return value ? format(parseISO(value), 'dd-MMM-yyyy') : ''
    },
  }

}
</script>

<style scoped>

  .card-hover-opacity{
    opacity: 0.4;
  }
  .card-not-hover-opacity{
    opacity: 1;
    transition: opacity .4s ease-in-out;
  }

  .show-btns {
    color: blue !important;
    opacity: 1;
  }

</style>